<template>
  <div>
    <SpinnerLoader v-if="categorySummaryStatus !== 'success'" :loading="categorySummaryStatus" />
    <template v-else-if="categorySummaryStatus === 'success' && categorySummary && categorySummary.totals">
      <virtual-scroll :items="categorySummary.items" :item-height="28.6" class="vscroll table-responsive mb-0 category-summary">
        <template slot-scope="{ items }">
          <table class="table table-sm small table-hover table-bordered ws-nowrap sticky-headers sticky-footer">
            <thead>
              <tr>
                <th>
                  <div class="resizable channel_name">{{ $t('channelModal.channel') }}</div>
                </th>
                <th>
                  <div class="resizable advertiser">{{ $t('table.advertiser') }}</div>
                </th>
                <th>
                  <div class="resizable brand">{{ $t('reports.product') }}</div>
                </th>
                <th>
                  <div class="resizable brand_group">{{ $t('reports.productCategory') }}</div>
                </th>
                <th>
                  <div class="resizable agency">{{ $t('table.agency') }}</div>
                </th>
                <th>
                  <div class="resizable type">{{ $t('table.commercialType') }}</div>
                </th>
                <th>{{ $t('channelModal.prime') }}/<br />{{ $t('channelModal.offPrime') }}</th>
                <th class="text-right">{{ $t('reports.cost') }}</th>
                <th class="text-right">{{ $t('reports.clearCost') }}</th>
                <th class="text-right">{{ $t('table.duration') }} <br />({{ $t('reports.min') }})</th>
                <th class="text-right">{{ $t('reports.baseTA') }} <br />({{ !showEquals ? 'wGRP' : $t('equalsWGRP.equals') }})</th>
                <!--  ALL TA wgrp cols here -->
                <th v-for="(ta, index) in categorySummary.totals.wgrp_target_audience" :key="index" class="text-right">
                  {{ index }}<br />({{ !showEquals ? 'wGRP' : $t('equalsWGRP.equals') }})
                </th>
                <th class="text-right">{{ $t('reports.priceFormation') }}</th>
                <th class="text-right">{{ $t('channelModal.year') }}</th>
                <th class="text-right">{{ $t('reports.month') }}</th>
                <th>
                  <div class="resizable desc">{{ $t('reports.description') }}</div>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in items" :key="`${item.brand_name}_${item.brand_group_name}_${item.agency_name}_${item.advertiser_name}_${index}`">
                <td name="channel_name">{{ item.channel_name }}</td>
                <td name="advertiser">{{ item.advertiser_name }}</td>
                <td name="brand">{{ item.brand_name }}</td>
                <td name="brand_group">{{ item.brand_group_name }}</td>
                <td name="agency">{{ item.agency_name }}</td>
                <td name="type">{{ item.commercial_type_name }}</td>
                <td>{{ item.is_prime_time ? $t('channelModal.prime') : $t('channelModal.offPrime') }}</td>
                <td class="text-right">{{ item.price_plan_fact | toFixedAndSpace }}</td>
                <td class="text-right">{{ item.net_price_plan_fact | toFixedAndSpace }}</td>
                <td class="text-right">{{ item.minutes_duration | toFixedAndSpace }}</td>
                <td class="text-right">{{ item.wgrp_base_target_audience | toFixedAndSpace }}</td>
                <!--  ALL TA wgrp cols here -->
                <td v-for="(taRating, taName) in categorySummary.totals.wgrp_target_audience" :key="taName" class="text-right">
                  <template v-if="item.wgrp_target_audience[taName] !== undefined">
                    {{ item.wgrp_target_audience[taName] | toFixedAndSpace }}
                  </template>
                </td>
                <td>{{ item.price_type }}</td>
                <td>{{ item.year }}</td>
                <td>{{ getMonthName(item.month) }}</td>
                <td name="desc">{{ item.project_name }}</td>
              </tr>
            </tbody>
            <tfoot class="font-weight-bold">
              <tr>
                <th colspan="7">{{ $t('booking.total') }}</th>
                <th class="text-right">{{ categorySummary.totals.price_plan_fact | toFixedAndSpace }}</th>
                <th class="text-right">{{ categorySummary.totals.net_price_plan_fact | toFixedAndSpace }}</th>
                <th class="text-right">{{ categorySummary.totals.minutes_duration | toFixedAndSpace }}</th>
                <th class="text-right">{{ categorySummary.totals.wgrp_base_target_audience | toFixedAndSpace }}</th>
                <!--  ALL TA wgrp cols here -->
                <th v-for="(ta, index) in categorySummary.totals.wgrp_target_audience" :key="index" class="text-right">{{ ta | toFixedAndSpace }}</th>
                <th colspan="4"></th>
              </tr>
            </tfoot>
          </table>
        </template>
      </virtual-scroll>
    </template>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import SpinnerLoader from '@/components/SpinnerLoader';
import toFixedAndSpace from '@/filters/toFixedAndSpace';
import VirtualScroll from '@/components/VirtualScroll';

export default {
  name: 'CostTable',
  components: { SpinnerLoader, VirtualScroll },
  filters: { toFixedAndSpace },
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      isLocale: 'isLocale',
      isThemeHeader: 'isTheme',
      categorySummary: 'getReportCategorySummary',
      categorySummaryStatus: 'getReportCategorySummaryStatus',
      sh_settings: 'getSalehousesSettings',
    }),
    showEquals() {
      return this.FEATURES.EQUALS && this.sh_settings[0]?.is_equals_enabled;
    },
    monthsList() {
      return [
        { id: 1, name: this.$i18n.t('month.jan') },
        { id: 2, name: this.$i18n.t('month.feb') },
        { id: 3, name: this.$i18n.t('month.mar') },
        { id: 4, name: this.$i18n.t('month.apr') },
        { id: 5, name: this.$i18n.t('month.may') },
        { id: 6, name: this.$i18n.t('month.jun') },
        { id: 7, name: this.$i18n.t('month.jul') },
        { id: 8, name: this.$i18n.t('month.aug') },
        { id: 9, name: this.$i18n.t('month.sep') },
        { id: 10, name: this.$i18n.t('month.oct') },
        { id: 11, name: this.$i18n.t('month.nov') },
        { id: 12, name: this.$i18n.t('month.dec') },
      ];
    },
  },
  destroyed() {
    this.$store.commit('clearReportCategorySummary');
  },
  methods: {
    getMonthName(month) {
      return this.monthsList.find((m) => m.id === +month)?.name;
    },
  },
};
</script>
<style lang="sass">
.reports-page div.table-responsive.category-summary
  height: calc(100vh - 154px)

.reports-page .category-summary table.table
  div.resizable
    &.channel_name,
    &.type,
    &.agency,
    &.advertiser,
    &.brand,
    &.brand_group
      min-width: 80px
    &.desc
      min-width: 120px

  td[name="channel_name"],
  td[name="type"],
  td[name="agency"],
  td[name="advertiser"],
  td[name="brand"],
  td[name="brand_group"]
    max-width: 80px
  td[name="desc"]
    max-width: 120px
</style>
